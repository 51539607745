import React from 'react';
import './LoginWelcome.css';

const LoginWelcome = () => {
  return (
    <div className="login-welcome-container">
      <h1>Welcome to Our Stock Analysis Platform!</h1>
      <p>
        Join us to access in-depth stock analysis tailored to your needs. Whether you're looking for technical indicators or fundamental analysis, we’ve got you covered. 
      </p>
      <p>
        Simply log in using your Google account and start exploring a world of insights that can help guide your investment decisions.
      </p>
      <p>Get started today and take control of your financial future!</p>
    </div>
  );
};

export default LoginWelcome;

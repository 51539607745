import { SERVER_URL } from "./config";
import axios from "axios";

export async function sendRequest(symbol) {
    try {
        const token = localStorage.getItem("bos-token")
        const url = SERVER_URL + "/new-ar";
        const res = await axios.post(url, { symbol }, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        return res.status === 200;
    } catch (error) {
        console.error('Error sending AR:', error);
        return false;
    }
}

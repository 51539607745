import React from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { sendRequest } from '../../api/be-client';
import './pp.css';
import { useNavigate } from 'react-router-dom';

export const Checkout = ({ selectedStock, setShowModal }) => {
    const [{ isPending }] = usePayPalScriptReducer();

    const nav = useNavigate()
    const onCreateOrder = (data, actions) => {
        // alert("Demo")
        // nav("/post-payment");
        // return;

        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: "0.1",
                    },
                },
            ],
        });
    };

    const onApproveOrder = (data, actions) => {
        return actions.order.capture().then(async (details) => {
            const name = details.payer.name.given_name;
            alert(`Transaction completed by ${name}`);
            const res = await sendRequest(selectedStock.symbol);
            if (res) {
                alert("Done");
                setShowModal(false);
                nav("/post-payment");
            } else {
                alert("Some error");
            }
        });
    };

    return (
        <div className="checkout">
            {isPending ? <p className="loading-text">LOADING...</p> : (
                <div className="paypal-buttons-container">
                    <p>
                        In order to get a technical analysis, your should proceed with paying 29.9$
                    </p>
                    <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={(data, actions) => onCreateOrder(data, actions)}
                        onApprove={(data, actions) => onApproveOrder(data, actions)}
                    />
                </div>
            )}
        </div>
    );
};

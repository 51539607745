import axios from "axios";
import { SERVER_URL } from "./config";

export async function getTokenByProviderCredential(credential, provider = "google") {
    try {
      const data = await axios.post(
        `${SERVER_URL}/auth/${provider}/`,
        JSON.stringify({ credential: credential }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      localStorage.setItem("bos-token", data.data.token);
      localStorage.setItem("bos-username", data.data.username);
  
      return data.data.username;
    } catch (error) {
      console.log(error);
      alert("ERROR. please try again later")
    }
  }
  
  
  export async function isTokenValid(token) {    
    
    try {
        const url = SERVER_URL + "/auth/is-token-valid";
        const res = await axios.get(url, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        return res.status === 200;
    } catch (error) {
        console.error('Error validating token:', error);
        return false;
    }
}

// import axios from "axios";
// import { SERVER_URL } from "./config";


// export async function login(un, pw) {
//     try {
//         const res = await axios.post(SERVER_URL + "/login",
//             {
//                 username: un,
//                 password: pw,
//             });

//         if (res.status == 200) {
//             const token = res.data.token;
//             localStorage.setItem("token", token);
//             return token;
//         } else {
//             return false;
//         }
//     } catch (error) {
//         console.log(error);
//         window.alert("Error at login");
//     }
// }

// export async function validateToken() {
//     const token = localStorage.getItem("token");

//     try {
//         const res = await axios.get(SERVER_URL + "/check-token", {
//             headers: { Authorization: `token ${token}` },
//         });
//         if (res.status == 200) {
//             return res.data;
//         } else {
//             return false;
//         }
//     } catch (error) {
//         console.log(error);    
//         window.alert("Error checking token");
//     }
// }

// export async function register(un, pw, email) {
//     const data = {
//         username: un,
//         password: pw,
//         email: email,
//     };

//     try {
//         const res = await axios.post(SERVER_URL + "/register", data);

//         if (res.status == 200) {
//             localStorage.setItem("token", res.data.token);
//             return res.data;
//         } else {
//             console.log(res.data)
//             window.alert("Error signup");
//             return false;
//         }
//     } catch (error) {
//         console.log(error)
//         window.alert("Error signup");
//         return false
//     }
// }
import React, { useContext, useEffect, useState } from 'react';
import "./Home.css";
import { stocksData } from '../../assets/stockData';
import { appContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import PaypalArea from '../paypalArea/PaypalArea';

const Home = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredStocks, setFilteredStocks] = useState(stocksData);
    const [selectedStock, setSelectedStock] = useState(null);
    const [showPPmodal, setShowPPmodal] = useState(false);
    const nav = useNavigate();
    const { userLogged } = useContext(appContext);

    useEffect(() => {
        if (!userLogged) {
            nav("/login");
        }
    }, []);

    const handleSearchChange = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        setFilteredStocks(
            Object.values(stocksData).filter(stock =>
                stock.name.toLowerCase().includes(term) ||
                stock.symbol.toLowerCase().includes(term)
            )
        );
    };

    const handleStockSelect = (stock) => {
        setSearchTerm("");
        setSelectedStock(stock);
    };

    const handleRequest = async () => {
        setShowPPmodal(true);
        // const res = await sendRequest(selectedStock.symbol);
        // if (res) {
        //     alert("Done");
        //     setSelectedStock(null);
        // } else {
        //     alert("Some error");
        // }
    };

    return (
        <div className="home-container">
            <h1>Search by Stock Symbol or Company Name</h1>
            <input
                type="text"
                placeholder="Search for a stock..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="stock-search-input"
            />
            <div className="stock-list">
                {searchTerm.length > 0 && filteredStocks.map((stock) => (
                    <button
                        key={stock.symbol}
                        className="stock-button"
                        onClick={() => handleStockSelect(stock)}
                    >
                        {stock.name} ({stock.symbol})
                    </button>
                ))}
            </div>
            {selectedStock && (
                <div className="stock-details">
                    <h2>{selectedStock.symbol}</h2>
                    <h3>{selectedStock.name}</h3>
                    {selectedStock.sector.length > 0 && <p><strong>Sector:</strong> {selectedStock.sector}</p>}
                    {selectedStock.country.length > 0 && <p><strong>Country:</strong> {selectedStock.country}</p>}
                    {selectedStock.industry.length > 0 && <p><strong>Industry:</strong> {selectedStock.industry}</p>}
                    <button className="analysis-button" onClick={handleRequest}>Request for Analysis</button>
                </div>
            )}
            <PaypalArea showModal={showPPmodal} setShowModal={setShowPPmodal} selectedStock={selectedStock} />
        </div>
    );
};

export default Home;
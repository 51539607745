import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PostPayment.css';

const PostPayment = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/home');
  };

  return (
    <div className="post-payment-container">
      <h1>Payment Successful!</h1>
      <p>You will receive an email shortly with your requested technical analysis.</p>
      <button onClick={handleRedirect} className="confirm-button">Back Home</button>
    </div>
  );
};

export default PostPayment;

import React from 'react';
import './Careers.css';
import { useNavigate } from 'react-router-dom';

const JobCard = ({ title, requirements }) => (
  <div className="job-card">
    <h2>{title}</h2>
    <ul>
      {requirements.map((req, index) => (
        <li key={index}>{req}</li>
      ))}
    </ul>
  </div>
);

const Careers = () => {
    const nav = useNavigate()
  return (
    <div className="careers-page">
      <div className="container">
        <h1>Join Our Mission</h1>
        <p className="subtitle">
          We're revolutionizing stock trading through AI-powered analytics and decision support.
        </p>
        
        <div className="about-us">
          <h2>About Us</h2>
          <p>
            At StockSmart AI, we're pioneering a new way to invest and manage stock trade decisions.
            Our cutting-edge AI algorithms provide actionable insights for investors of all levels.
          </p>
        </div>

        <JobCard
          title="Product Manager (Finance Background)"
          requirements={[
            "Strong experience in financial markets and product management",
            "5+ years of experience in a leadership role",
            "Excellent communication and team collaboration skills",
            "Experience working closely with developers and clients"
          ]}
        />
        
        <JobCard
          title="AI/ML Engineer"
          requirements={[
            "Expertise in machine learning algorithms and financial modeling",
            "Proficiency in Python, TensorFlow, and SQL",
            "Experience with time series analysis and predictive modeling",
            "3+ years of experience in quantitative finance or related field"
          ]}
        />

        <div className="apply-section">
          <h2>How to Apply</h2>
          <p>
            Send your resume to <span className="email">careers@stocks-analysis.com</span>
          </p>
          <button className="home-button" onClick={()=>{nav("/home")}}>
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Careers;
import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <p>
        Welcome to our platform! We are dedicated to providing investors with
        the tools and insights they need to make informed decisions in the
        stock market. Our user-friendly interface allows you to register,
        search for stocks, and request in-depth analyses for a fee.
      </p>
      <h2>Our Team</h2>
      <p>
        Our company is based in Israel and is led by a passionate team of
        experts:
      </p>
      <ul>
        <li>
          <strong>Ori Brook</strong> - CTO: With extensive experience in
          technology and finance, Ori ensures our platform runs smoothly and
          efficiently.
        </li>
        <li>
          <strong>Chen Mizrachi</strong> - Founder: A visionary in the
          investment sector, Chen's insights shape our mission and values.
        </li>
      </ul>
      <h2>Our Services</h2>
      <p>
        We offer both technical and fundamental analysis of stocks. Once you
        register, you can search for any stock and request a detailed analysis
        to help you understand the market trends and make better investment
        decisions.
      </p>
      <p>
        Join us today and take the first step toward becoming a more
        informed investor!
      </p>
    </div>
  );
};

export default About;

import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Checkout } from "./Checkout";
import { Modal } from "react-bootstrap";
import './pp.css';

const initialOptions = {
  "client-id": "AZXNCIOs88aDB_ODMRh7ZIwZ3AGZ6TuVBUQLtoFQV0QfUUm8FGiaboOuwgZM86UX_qCccXSKIeEvpQpO",
  currency: "USD",
  intent: "capture",
};

export default function PaypalArea({ showModal, setShowModal, selectedStock }) {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
      <div className="modal-content">
        <PayPalScriptProvider options={initialOptions}>
          <Checkout selectedStock={selectedStock} setShowModal={setShowModal} />
        </PayPalScriptProvider>
      </div>
    </Modal>
  );
}

import React from 'react';
import './ApiPage.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

const ApiPage = () => {
    const nav = useNavigate();
  return (
    <div className="api-container">
      <h1>API Integration</h1>
      <p>If you are looking to integrate your trading system with our stock analysis platform, please contact us at:</p>
      <a href="mailto:support@stocks-analysis.com" className="support-email">
        support@stocks-analysis.com
      </a>
          <p>Our team will assist you with API documentation and setup to meet your needs.</p>
          <br/>
          <br />
          <button className="home-button" onClick={()=>{nav("/home")}}>
            Back to Home
          </button>
    </div>
  );
};

export default ApiPage;

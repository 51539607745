import React, { useContext, useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { appContext } from "../../App";
import { GOOGLE_AUTH_CLIENT_ID } from "../../api/config";
import { getTokenByProviderCredential } from "../../api/authApi";
import LoginWelcome from "../loginWelcome/LoginWelcome";

const clientId = GOOGLE_AUTH_CLIENT_ID;

const Login = () => {
  const nav = useNavigate();

  const { setUserLogged, setUserName, userLogged } = useContext(appContext);

  useEffect(() => {
    if (userLogged) {
      nav("/home");
    }
  }, []);

  const onSuccess = async (response) => {
    console.log("Login Success at FE side:", response);
    const userName = await getTokenByProviderCredential(
      response.credential,
      "google"
    );
    setUserName(userName);
    setUserLogged(true);
    nav("/prompt");
  };

  const onFailure = (response) => {
    console.log("Login Failed:", response);
  };

  return (
    <div>
      <LoginWelcome/>

    <GoogleOAuthProvider clientId={clientId}>
      <div className="login-container">
     
        <div className="google-auth-container">
          <GoogleLogin
            clientId={clientId}
            onSuccess={onSuccess}
            onFailure={onFailure}
            buttonText="Continue with Google"
            className="google-login-button"
          />
        </div>
        
      </div>
    </GoogleOAuthProvider>
    </div>
  );
};

export default Login;